




















































































































































import { defineComponent } from '@vue/composition-api'
import { useErrors } from '@/components/composables/useErrors'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import UserService from '@/services/user'
import { RoleDTO, UserFormDTO } from '@/services/user/types'
import { errorMessages } from '@/utils/helpers'
import TabsSwitcher from '@/components/TabsSwitcher.vue'
import ResendActivationEmailButton from '@/components/buttons/ResendActivationEmailButton.vue'
import { UserStatus } from '@/views/User/types'
import UserStatusChip from '@/views/User/partials/UserStatusChip.vue'

export default defineComponent({
  name: 'UserForm',
  setup() {
    const { errors, clearFieldError, clearErrors } = useErrors()
    return { errors, clearFieldError, clearErrors }
  },
  components: { ResendActivationEmailButton, TabsSwitcher, CardTitle, SubmitButton, CancelButton, UserStatusChip },
  props: {
    id: { type: String, default: '' },
    viewType: { type: String, default: 'create' },
  },
  data: function (): {
    data: UserFormDTO
    innerHeight: number
    roles: RoleDTO[]
    UserStatus: typeof UserStatus
  } {
    return {
      innerHeight,
      roles: [],
      data: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        roleId: NaN,
        status: UserStatus.NOT_ACTIVATED,
        businessUnitCode: '',
        operatorCode: '',
        tcrCode: '',
        tcrMaintainerCode: '',
      },
      UserStatus,
    }
  },
  mounted() {
    addEventListener('resize', () => (this.innerHeight = innerHeight))
  },
  beforeMount() {
    this.fetchRoles()
    if (this.id) this.fetchItem()
  },
  methods: {
    async fetchRoles(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const { data } = await UserService.roles()
        if (data) this.roles = data.data
      } finally {
        loader.hide()
      }
    },
    async fetchItem(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const { data } = await UserService.find(this.id)
        this.data = data.data.user
      } finally {
        loader.hide()
      }
    },
    async save(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await UserService.update(this.id, this.data)
        if (response) {
          this.$toast.success(response.data.message)
          await this.$router.push({ name: 'Users' })
        }
      } catch (err) {
        if (err.response.data) {
          this.errors.value = err.response.data.errors || {}
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    async add(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await UserService.create(this.data)
        if (response) {
          this.$toast.success(response.data.message)
          await this.$router.push({ name: 'Users' })
        }
      } catch (err) {
        if (err.response.data) {
          this.errors.value = err.response.data.errors || {}
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
  computed: {
    isShowMode(): boolean {
      return this.viewType === 'show'
    },
    isCreateMode(): boolean {
      return this.viewType === 'create'
    },
    isEditMode(): boolean {
      return this.viewType === 'edit'
    },
    tabs(): string[] {
      return [this.$tc('details'), this.$tc('roles'), this.$tc('fiscal')]
    },
  },
})
